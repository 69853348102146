<template>
  <v-container>
    <title-header />
    <v-card>
      <v-row class="px-5 py-1 d-flex justify-space-between">
        <v-col
          class="d-inline-flex align-center"
          cols="12"
          sm="12"
          md="4"
          xl="3"
        >
          <h3 class="title-card d-flex align-center">
            <span class="font-weight-medium mr-2">Motorista:</span>
            <user-chip :user="userFormatted" />
          </h3>
        </v-col>
        <v-col
          class="d-inline-flex align-center"
          cols="12"
          sm="12"
          md="4"
          xl="3"
        >
          <span class="mr-2 font-weight-medium">CRC:</span>
          <span>{{ user.selectedCrc.description }}</span>
        </v-col>
      </v-row>
    </v-card>
    <v-card class="mt-5">
      <template v-if="collectionSchedule.length">
        <v-list>
          <template v-for="(item, index) in collectionSchedule">
            <v-list-item :key="item.id">
              <v-list-item-content>
                <v-row>
                  <v-col cols="12" sm="12" md="3" xl="4">
                    <div
                      class="d-flex flex-wrap list-content--text black-1--text"
                    >
                      <span class="font-weight-medium mr-3"
                        >Agendamento {{ item.id }}</span
                      >
                      <div>
                        <span class="font-weight-medium">Data:</span>
                        <span class="ml-1">{{ item.createdAt }}</span>
                      </div>
                    </div>
                    <p class="mt-3 list-content--text black-1--text">
                      <span class="font-weight-medium"
                        >Status da solicitação:</span
                      >
                      <span class="ml-1">{{ item.situation }}</span>
                    </p>
                  </v-col>
                  <v-col cols="12" sm="12" md="7" xl="6">
                    <div
                      class="white-3--text d-flex flex-wrap list-content--text"
                    >
                      <div>
                        <span class="font-weight-medium">Turno:</span>
                        <span class="ml-1 mr-3">{{ item.shift }}</span>
                      </div>
                      <div>
                        <span class="font-weight-medium">Motorista:</span>
                        <span class="ml-1 mr-3">{{ item.driver }}</span>
                      </div>
                      <div>
                        <span class="font-weight-medium">Coletas:</span>
                        <span class="ml-1 mr-3">
                          {{ item.amountOfCollections }}
                        </span>
                      </div>
                    </div>
                    <div class="primary-color-1--text mt-3 list-content--text">
                      <span class="font-weight-medium">Observações:</span>
                      <span class="ml-1">{{ item.observation }}</span>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="12" md="2" xl="2">
                    <v-btn
                      @click="goToViewRequest(item.id)"
                      color="secondary-color-1"
                      max-width="100"
                      class="white--text next-step-button"
                      >Ver mais</v-btn
                    >
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
            <v-divider
              v-if="index < collectionSchedule.length - 1"
              :key="'divider-' + index"
            ></v-divider>
          </template>
        </v-list>
        <div v-if="collectionSchedule.length">
          <pagination
            :currentPage="tableInfo.currentPage"
            :totalPages="tableInfo.totalPages"
            :itemsPerPage="tableInfo.itemsPerPage"
            @currentPageChanged="handleCurrentPageChange($event)"
            @itemsPerPageChanged="handleItemsPerPageChange($event)"
          />
        </div>
      </template>

      <template v-if="!collectionSchedule.length">
        <div class="pt-10">
          <data-not-found
            title="Você ainda não possui nenhuma coleta agendada"
            :imgSrc="require('@/assets/truck.svg')"
          />
        </div>
      </template>
    </v-card>
  </v-container>
</template>
<script>
  import { parseISO } from "date-fns";
  import { mapActions, mapGetters } from "vuex";

  import TableHandler from "@/modules/core/mixins/tableHandler";
  import MasksMixin from "@/modules/core/mixins/masksMixin";
  import { profiles } from "@/modules/core/enums/profiles.enum";
  import TitleHeader from "@/modules/core/views/components/TitleHeader";
  import Pagination from "@/modules/core/views/components/Pagination";
  import DataNotFound from "@/modules/core/views/components/DataNotFound";
  import UserChip from "@/modules/account/user/views/components/UserChip";

  export default {
    name: "MyCollections",

    mixins: [MasksMixin, TableHandler],

    components: {
      Pagination,
      TitleHeader,
      UserChip,
      DataNotFound
    },

    data: () => ({
      collectionSchedule: []
    }),

    computed: {
      ...mapGetters({
        user: "user/getUser"
      }),
      userFormatted() {
        return {
          fullName: `${this.user?.firstName} ${this.user?.surname}`,
          photo: this.user?.photoHash
        };
      }
    },

    async mounted() {
      await this.getColletionData();
    },

    methods: {
      ...mapActions(["toggleSnackbar"]),
      ...mapActions("agenda", ["getCollectionSchedule"]),

      async getColletionData() {
        try {
          const { data, meta } = await this.getCollectionSchedule();
          this.collectionSchedule = this.formatCollectionScheduleData(data);

          this.setTableInfo(meta);
        } catch (err) {
          this.toggleSnackbar({
            text: err?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        }
      },

      formatCollectionScheduleData(collection) {
        return collection.map(data => {
          const parsedDate = parseISO(data.scheduleDate);

          return {
            ...data,
            id: data.id,
            createdAt: this.parseDate(parsedDate, "dd/MM/yyyy"),
            shift: data.shift,
            driver: `${data.driver?.firstName} ${data.driver?.surname}`,
            amountOfCollections: data.amountOfCollections,
            situation: data.situation?.description,
            address: data.address
          };
        });
      },

      goToViewRequest(requestId) {
        return this.$router.push({
          name: "collectionDetail",
          params: { id: requestId }
        });
      },

      updateData() {
        this.getColletionData();
      }
    }
  };
</script>
<style lang="scss" scoped>
  @import "@/assets/stylesheets/styleguide";
  @import "@/assets/stylesheets/components";

  .title-card {
    color: $black-1;
    opacity: 1;
    font-size: 16px;
  }

  .list-content--text {
    font-size: 14px;
  }
</style>
